<template>
  <div class="flex flex-col h-full">
    <div class="px-4 py-2 bg-white">
      <router-link class="flex-1" to="/search">
        <input-box placeholder="大家都在搜什么" ></input-box>
      </router-link>
    </div>
    <div class="flex flex-1 overflow-hidden items-stretch container" >
      <div class=" bg-gray-100 w-28 relative">
        <cube-scroll
          ref="scroll"
          :data="categoryMenus"
          :options="options">
          <div class="container mx-auto">
            <div @click="current = index" 
              class="p-4 text-sm relative" 
              v-for="(item,index) in categoryMenus" 
              :key="index"
              :class="{'bg-white text-primary font-medium': current == index}"
            >
            <span class="w-1 h-4 bg-primary cl" v-if="current == index"></span>
            {{item.text}}
            </div>
          </div>
        </cube-scroll>
      </div>
      <div class="flex-1 overflow-hidden bg-white">
        <cube-scroll
          ref="scroll"
          :data="TestData.examines"
          :options="options">
          <list :empty="!TestData.examines.length">
            <media-item 
              url="/" 
              v-for="item in TestData.examines" 
              :key="item.id" 
              :image="item.image" 
              :title="item.title"  
              img-ratio="16/15"
              img-width="80"
              note="有了它你再也不用愁了"
            >
              <div class="flex items-center justify-between mt-1" slot="footer">
                <div class="text-red-500 font-medium"><span class="text-xs">¥</span>89.9</div>
                <div class="text-xs text-gray-light">已售3923件</div>
              </div>
            </media-item>
          </list>
        </cube-scroll>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import { CardItem, InputBox } from '@/components'
export default {
  components: {
    CardItem,
    InputBox
  },
  computed: {
    ...mapGetters([
      'TestData'
    ]),
    options() {
      return {
        scrollbar: this.scrollbar,
        startY: 0
      }
    },
  },
  data() {
    return {
      current: 0,
      categoryMenus: [
        {text: "三星", id: 1},
        {text: "苹果", id: 2},
        {text: "小米", id: 3},
        {text: "华为", id: 4},
        {text: "VIVO", id: 5},
        {text: "OPOP", id: 6},
        {text: "一加", id: 7},
        {text: "魅族", id: 8},
        {text: "联想", id: 9},
        {text: "摩托罗拉", id: 10},
        {text: "诺基亚", id: 11},
      ]
    }
  },

  methods: {
    
  },
  created() {
    
  }
}
</script>

<style>

</style>